<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { routes } from "./router/routes.ts";

export default {
  data() {
    return { routes };
  },
};
</script>

<style>
#app {
  background: linear-gradient(#ffffff, #a3c4f9);
}
</style>
