import { render, staticRenderFns } from "./PreferentialMain.vue?vue&type=template&id=69afd3da&scoped=true&"
import script from "./PreferentialMain.vue?vue&type=script&lang=js&"
export * from "./PreferentialMain.vue?vue&type=script&lang=js&"
import style0 from "./PreferentialMain.vue?vue&type=style&index=0&id=69afd3da&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69afd3da",
  null
  
)

export default component.exports