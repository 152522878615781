<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-card
          @click="salvarFila('Convencional'), getNowDate(), resetCacheTimer()"
          class="mx-auto"
          width="500"
          height="70"
          elevation="3"
          v-bind="attrs"
          v-on="on"
          color="#f2f2f2"
        >
          <v-card-title class="text-center justify-center">
            <b>CONVENCIONAL</b>
          </v-card-title>
        </v-card>

        <v-card
          @click="salvarFila('Preferencial'), getNowDate(), resetCacheTimer()"
          class="mx-auto mt-6"
          width="500"
          height="70"
          elevation="3"
          v-bind="attrs"
          v-on="on"
          color="#f2f2f2"
        >
          <v-card-title class="text-center justify-center">
            <b>PREFERENCIAL</b>
          </v-card-title>
        </v-card>
      </template>

      <v-card class="color" height="400">
        <LayoutPassword
          class="password-layout"
          id="password-print-id"
          :password="this.password"
          :hora="this.hour"
          :data="this.date"
          :type="this.atendimento.type"
        />
        <div class="container" v-if="!errorOnGeneratePassword">
          <div class="title-type">
            Tipo <b>{{ this.atendimento.type }}</b>
          </div>

          <div class="password">
            {{ this.password }}
          </div>

          <div class="msg">Retire sua senha e aguarde ser chamado.</div>

          <div class="flex-container">
            Voltando para a tela inicial em {{ timerCount }}.
          </div>
        </div>
        <div class="container" style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        " v-else>
          <div class="msg">
            Ocorreu um erro ao gerar senha.
          </div>
          <div class="msg">
            {{errorMsg}}
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Printd } from "printd";
import api from "@/services/conexao-api";
import LayoutPassword from "./LayoutPassword.vue";

export default {
  name: "PreferentialMain",
  components: { LayoutPassword },

  data() {
    return {
      password: "",
      drawer: null,
      selected: [],
      dialog: false,
      atendimento: { type: "", services: [], createdAt: null },
      hour: "",
      date: "",
      searchParams: this.$route.query,
      timerCount: 4,
      timerEnable: false,
      errorOnGeneratePassword: false,
      errorMsg: ''
    };
  },
  methods: {
    salvarFila(tipo) {
      this.atendimento.type = tipo;
      this.atendimento.services = this.searchParams.selected;
      this.atendimento.createdAt = new Date().toUTCString();
      api
        .post("/attendance/generate-attendance", this.atendimento)
        .then((response) => (this.password = response.data))
        .catch((err)=> {
          this.errorOnGeneratePassword = true;
          this.errorMsg = err.response?.data?.message || ""
          console.log(err.response?.data?.message || "Ocorreu um erro ao gerar a senha");
        })
        .finally(() => {
          if(!this.errorOnGeneratePassword) {
            this.imprimir();
          }
        });
    },
    imprimir() {
      const printD = new Printd();
      printD.print(document.getElementById("password-print-id"));
    },
    getNowDate() {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hour = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      this.date = `${day}/${month}/${year}`;
      this.hour = `${hour}:${minutes}`;
    },
    resetCacheTimer() {
      this.timerEnable = true;
      setTimeout(() => {
        this.errorOnGeneratePassword = false
        this.$router.push({ path: "/" });
        sessionStorage.removeItem("selected");
      }, 4000);
    },
  },

  watch: {
    timerEnable(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnable) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.color {
  background-color: #5c9df2;
}
.container {
  position: relative;
  top: 6%;
  height: 350px;
  width: 450px;
  padding: 30px 20px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  text-align: center;
}
.container .title-type {
  margin-bottom: 5%;
  margin-top: 3%;
  font-size: 25px;
  padding: 5px;
}
.container .password {
  margin-bottom: 4%;
  font-size: 80px;
  font-weight: 600;
}
.container .msg {
  font-size: 22px;
  padding: 5px;
  margin-bottom: 3%;
}
.flex-container {
  display: flex;
  justify-content: center;
  margin-top: 3%;
  font-size: 15px;
}
.password-layout {
  display: none;
}
@media print {
  .password-layout {
    display: block;
  }
}
</style>
