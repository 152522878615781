<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      max-width="1000px"
      persistent
      scrollable
      color="#f2f2f2"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          height="60"
          width="200"
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="$emit('listDocuments')"
          :disabled="isDisabled"
        >
          <h3>Confirmar</h3>
        </v-btn>
      </template>
      <v-card>
        <div class="scroll img-documents" max-height="75px">
          <div class="font-serv-doc title-margin-align">Beneficiário(a),</div>

          <div class="font-title title-margin-align">
            Esses foram os serviços selecionados por você:
          </div>

          <div class="card-background">
            <div
              v-for="service in services"
              :key="service.id"
              class="doc-serv-align"
            >
              <div class="font-serv-doc">
                <img
                  src="../../assets/seta.png"
                  alt=""
                  height="20px"
                  width="20px"
                />
                {{ service.name.toUpperCase() }}
              </div>
            </div>
          </div>

          <div class="font-title title-margin-align">
            Os documentos abaixo ajudarão na realização do seu atendimento:
          </div>

          <div class="card-background">
            <div
              v-for="document in documents"
              :key="document.id"
              class="doc-serv-align"
            >
              <div class="font-serv-doc">
                <img
                  src="../../assets/seta.png"
                  alt=""
                  height="20px"
                  width="20px"
                />
                {{ document }}
              </div>
            </div>
          </div>
        </div>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <div class="font-button">Deseja prosseguir?</div>
          <div class="flex-container">
            <button class="button-not" text @click="dialog = false">
              <h3 class="h3-white">Não</h3>
            </button>
            <button class="button-yes" text @click="$emit('salvarSelect')">
              <h3 class="h3-white">Sim</h3>
            </button>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DocumentsDialog",
  emits: ["salvarSelect", "listDocuments"],
  props: ["services", "documents"],
  data() {
    return {
      dialog: false,
      drawer: null,
    };
  },

  computed: {
    isDisabled () {
      if (this.services.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
  height: 600px;
  padding-block: 25px;
  padding-left: 30px;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-button {
  width: 8px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #fff;
  border: #fff;
  box-shadow: 0px 0px 3px #fff inset;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #999;
  border: thin solid gray;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}
.img-documents {
  background-image: url("../../assets/doc-dialogue.png");
  background-size: cover;
  background-size: 40%;
  background-position: right 0% bottom 20%;
}
.center-div {
  margin: auto;
  width: 50%;
}
.font-title {
  font-style: normal;
  font-size: 21px;
  font-weight: lighter;
  color: #707070;
}
.font-serv-doc {
  font-weight: bolder;
  font-size: 21px;
  color: #4f4f4f;
}

.font-button {
  font-weight: bolder;
  font-size: 27px;
  color: #4f4f4f;
}
.title-margin-align {
  margin-left: 18px;
  margin-top: 20px;
  margin-bottom: 15px;
  width: 600px;
}
.doc-serv-align {
  margin-left: 10px;
}
.card-background {
  background-color: #f7f7f7;
  width: 550px;
  border-radius: 5px;
  margin-left: 25px;
}
.button-not {
  margin-left: 20px;
  background-color: #ff5252;
  border-radius: 5px;
  padding: 12px 50px;
}
.button-yes {
  background-color: #1976d2;
  padding: 12px 50px;
  border-radius: 4px;
  margin-left: 40px;
}
.flex-container {
  display: flex;
  align-items: relative;
  margin: 5px;
  margin-right: 220px;
  justify-content: space-between;
  right: 50px;
}
.h3-white {
  color: white;
}
</style>
