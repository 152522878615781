<template>
  <div>
    <div id="senha" class="container"
      style="
        
          margin: 0;
          box-sizing: border-box;
          font-family: 'VT323', monospace;
          color: #1f1f1f;
          width: 100vw;
          height: 120px;
          
       
      "
    >
      <div class="receipt" style="background: #fff; margin: 0; width: 100%; display: block !important;">
        <div class="survey bold"
          style="text-align: center;margin-bottom: 0; display: block !important; line-height:0.2"
        >
          <h1 style="font-weight: bold; font-size: 30px;line-height: 0;">SENHA</h1>
          <h3 style="line-height: 1;">{{ type }}</h3>
          <h1 class="surveyID" style="font-size: 50px; ">{{ password }}</h1>
        </div>

        <div class="dateTime" style="line-height:1">
          <div class="time" style="text-align: center; margin-bottom: 0;">{{ hora }}</div>
          <div class="date" style="text-align: center; margin-bottom: 0">
            {{ data }}
          </div>
        </div>

        <div class="feedback" style="line-height: 0.3">
          <p class="center" style="text-align: center">Aguarde ser chamado</p>

          <div class="break"
            style="
              text-align: center;
              font-size: 18px;
              font-weight: bold;
              margin: 10px 0;
            "
          >
            -----------------
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutPassword",
  props: ["password", "hora", "data", "type"],
};
</script>
