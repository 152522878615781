import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import { routes } from './router/routes'

Vue.use(VueRouter);

Vue.config.productionTip = false

const router = new VueRouter({
  routes: routes,
  mode: 'history'
});

new Vue({
  el: '#app',
  router: router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
