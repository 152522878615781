<template>
  <v-app>
    <div class="img-background">
      <img class="woman-img" src="../../assets/woman-doc.png" />
      <img class="man-attendance-pic" src="../../assets/man-attendance.png" />
      <br />
      <div class="container-attendance-component">
        <v-img class="mx-auto"
          lazy-src="../../assets/cassems-logo.png"
          max-height="150"
          max-width="150"
          src="../../assets/cassems-logo.png"
        />
        <div class="welcome-text">Bem-vindo, como podemos te ajudar hoje?</div>

        <div class="service-selection-text">
          Selecione os serviços que deseja realizar:
        </div>
        <AttendanceMain />
      </div>
    </div>
  </v-app>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import attendancemain from "../../components/attendanceComp/AttendanceMain.vue";

export default {
  components: {
    AttendanceMain: attendancemain,
  },
};
</script>

<style>
.img-background {
  background-image: url("../../assets/fundo.png");
  background-size: cover;
  background-size: 50%;
  background-position: 50% -100%;
}

.welcome-text {
  margin-top: 20px;
  text-align: center;
  font-size: 25px;
  font-weight: normal;
  color: #4f4f4f;
}

.service-selection-text {
  margin-top: 20px;
  text-align: center;
  font-size: 36px;
  font-weight: bolder;
  color: #4f4f4f;
}

.woman-img {
  height: 480px;
  width: 230px;
  position: absolute;
  bottom: 0px;
  margin-right: 15px;
}

.man-attendance-pic {
  height: 480px;
  width: 205px;
  position: absolute;
  bottom: 0px;
  right: 35px;
}

.container-attendance-component{
  align-content: center;
  margin-top: 6%;
}
</style>
